import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createVehicle, getRegisteredVehicles, updateVehicleDetails } from './registeredVehiclesAPI';

const initialState = {
    registeredVehicles: [],
    registeredVehicle: {},
    isLoading: false,
    isError: false,
    error: "",
}

export const fetchRegisteredVehicles = createAsyncThunk(
    'registerVehicles/getRegisterVehicles',
    async () => {
        const vehicles = await getRegisteredVehicles();
        return vehicles;
    }
)

export const onUpdateVehicleDetails = createAsyncThunk(
    "registerVehicles/updateVehicle",
    async (requestBody) => {
        const data = await updateVehicleDetails(requestBody);
        return data
    }
)

export const onCreateVehicle = createAsyncThunk(
    "registerVehicles/createVehicle",
    async (requestBody) => {
        const data = await createVehicle(requestBody);
        return data
    }
)


const registerVehiclesReducer = createSlice({
    name: 'registerVehicles',
    initialState,
    extraReducers: (builder) => {
        // fetch chargingStation
        builder
            .addCase(fetchRegisteredVehicles.pending, (state) => {
                state.isError = false;
                state.isLoading = true;
            }).addCase(fetchRegisteredVehicles.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.registeredVehicles = payload;
            }).addCase(fetchRegisteredVehicles.rejected, (state, { error }) => {
                state.isLoading = false;
                state.registeredVehicles = [];
                state.error = error?.message;
            })

        // update vehicle details
        builder
            .addCase(onUpdateVehicleDetails.pending, (state) => {
                state.isError = false;
                state.isLoading = true;
            }).addCase(onUpdateVehicleDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.registeredVehicle = payload;
            }).addCase(onUpdateVehicleDetails.rejected, (state, { error }) => {
                state.isLoading = false;
                state.registeredVehicle = {};
                state.error = error?.message;
            })
  // update vehicle details
  builder
  .addCase(onCreateVehicle.pending, (state) => {
      state.isError = false;
      state.isLoading = true;
  }).addCase(onCreateVehicle.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.registeredVehicles =[ payload, ...state.registeredVehicles];
  }).addCase(onCreateVehicle.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error?.message;
  })
    },
})


export default registerVehiclesReducer.reducer