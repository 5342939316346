import { Box, Button, Divider, Grid, Input, Paper, Typography } from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Preloader from '../components/Preloader';
import { db } from '../firebase/firebase.config';
import { onUpdateVehicleDetails } from '../redux/registeredVehicles/registeredVehiclesSlice';
const VehiclesDetails = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const [vehicle, setVehicle] = useState({});
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false)
    const [updatedFields, setUpdatedFields] = useState(null)

    const onEditDetails = () => {
        setEditMode(!editMode)
        setUpdatedFields(vehicle)
    }

    const updateFields = (e) => {
        let fields = { ...updatedFields, [e.target.name]: e.target.value }
        setUpdatedFields(fields)
    }

    useEffect(() => {
        if (id) {
            setLoading(true)
            const unsubscribe = onSnapshot(doc(db, "registeredVehicle", id), (doc) => {
                if (doc.exists()) {
                    setVehicle(doc.data());
                }
                setLoading(false)
            });
            return () => {
                unsubscribe()
            }
        }
    }, [id])

    const submitHandler = () => {
        dispatch(onUpdateVehicleDetails({id, updatedFields}))
        setEditMode(false)
    }


    return (
        <>

            {
                loading ? <Preloader /> :
                    <Paper sx={{ mt: 5, px: 3, py: 2 }} elevation={0}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant='h6' color="primary">General Information:</Typography>
                            <Link onClick={() => onEditDetails()}>{editMode ? "Cancel" : "Edit"}</Link>
                        </div>
                        <Divider sx={{ my: 2 }} />
                        <Grid container rowSpacing={0} columnSpacing={8}>
                            <Grid item lg={12} xs={12} sx={{ "& pre": { fontFamily: "inherit" } }}>
                                <Box sx={{ display: "flex" }}>
                                    {!editMode && <Typography sx={{ fontWeight: 700, mr: 2, minWidth: "200px" }}>
                                        Plate Number: <br />
                                        Vin Number: <br />
                                        Brand Name: <br />
                                        Serial Number: <br />
                                        IMEI: <br />
                                        NFTID:
                                    </Typography>}
                                    <Typography>
                                        {editMode ? <div>Plate Number: <Input value={updatedFields.plateNumber} name="plateNumber" onChange={updateFields} /></div> : vehicle?.plateNumber} <br />
                                        {editMode ? <div>Vin Number: <Input value={updatedFields.vinNumber} name="vinNumber" onChange={updateFields} /></div> : vehicle?.vinNumber} <br />
                                        {editMode ? <div>Brand Name: <Input value={updatedFields.brandName} name="brandName" onChange={updateFields} /></div> : vehicle?.brandName} <br />
                                        {editMode ? <div>Serial Number: <Input value={updatedFields.serialNumber} name="serialNumber" onChange={updateFields} /></div> : vehicle?.serialNumber} <br />
                                        {editMode ? <div>IMEI: <Input value={updatedFields.IMEI} name="IMEI" onChange={updateFields} /></div> : vehicle?.IMEI} <br />
                                        {editMode ? <div>NFTID: <Input value={updatedFields.algorandNFTID} name="algorandNFTID" onChange={updateFields} /></div> : vehicle?.algorandNFTID} <br />
                                    </Typography>
                                </Box>



                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Box sx={{ display: "flex" }}>
                                    {!editMode && <Typography sx={{ fontWeight: 700, mr: 2, minWidth: "200px" }}>
                                        Location: <br />
                                        QR Code: <br />
                                        {/* Tokens: <br /> */}
                                        Charge Precentage:
                                    </Typography>}
                                    <Typography>
                                        {editMode ? <div>Location: <Input value={updatedFields.location} name="location" onChange={updateFields} /></div> : vehicle?.location} <br />
                                        {editMode ? <div>QR Code: <Input value={updatedFields.qrCode} name="qrCode" onChange={updateFields} /></div> : vehicle?.qrCode} <br />
                                        {/* {editMode ? <div>Tokens: <Input value={updatedFields.tokens} name="tokens" onChange={updateFields} /></div> : vehicle?.tokens} <br /> */}
                                        {editMode ? <div>Charge Precentage: <Input value={updatedFields.chargePrecentage} name="chargePrecentage" onChange={updateFields} /></div> : vehicle?.chargePrecentage} <br />
                                    </Typography>
                                </Box>

                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Box sx={{ display: "flex" }}>
                                    {!editMode && <Typography sx={{ fontWeight: 700, mr: 2, minWidth: "200px" }}>
                                        Token 1 Owner:  <br />
                                        Token 2 Owner: <br />
                                        Token 3 Owner: <br />
                                        Token 4 Owner:
                                    </Typography>}
                                    <Typography>
                                        {editMode ? <div>Token 1 Owner: <Input value={updatedFields.token1Owner} name="token1Owner" onChange={updateFields} /></div> : vehicle?.token1Owner} <br />
                                        {editMode ? <div>Token 2 Owner: <Input value={updatedFields.token2Owner} name="token2Owner" onChange={updateFields} /></div> : vehicle?.token2Owner} <br />
                                        {editMode ? <div>Token 3 Owner: <Input value={updatedFields.token3Owner} name="token3Owner" onChange={updateFields} /></div> : vehicle?.token3Owner} <br />
                                        {editMode ? <div>Token 4 Owner: <Input value={updatedFields.token4Owner} name="token4Owner" onChange={updateFields} /></div> : vehicle?.token4Owner} <br />
                                    </Typography>
                                </Box>
                                {editMode && <Button onClick={submitHandler}>Submit Changes</Button>}
                            </Grid>
                        </Grid>
                    </Paper>
            }

        </>
    );
};

export default VehiclesDetails;