import { Box, Button, Collapse, Divider, Input, List, ListItem, Modal, Typography } from "@mui/material"
import React, { useState } from "react"
import { useDispatch } from "react-redux";
import { onCreateVehicle } from "../../redux/registeredVehicles/registeredVehiclesSlice";

const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh'
};

export const NewVehicle = ({ item }) => {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [updatedFields, setUpdatedFields] = useState({})


    const updateFields = (e) => {
        let fields = { ...updatedFields, [e.target.name]: e.target.value }
        setUpdatedFields(fields)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(onCreateVehicle({ id: updatedFields.plateNumber, updatedFields }))
        setUpdatedFields({})
        handleClose()
    }

    return (
        <div>
            <Button variant="contained" style={{ marginLeft: '1rem' }} onClick={handleOpen}>Add +</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ overflow: 'scroll' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add New Vehicle
                    </Typography>
                    <br></br>
                    <form onSubmit={submitHandler}>
                        <Typography>
                            <div>Plate Number:<br /> <Input value={updatedFields?.plateNumber} name="plateNumber" onChange={updateFields} required /></div><br />
                            <div>Vin Number:<br /> <Input value={updatedFields?.vinNumber} name="vinNumber" onChange={updateFields} required /></div><br />
                            <div>Brand Name:<br /> <Input value={updatedFields?.brandName} name="brandName" onChange={updateFields} required /></div><br />
                            <div>Serial Number:<br /> <Input value={updatedFields?.serialNumber} name="serialNumber" onChange={updateFields} required /></div><br />
                            <div>IMEI:<br /> <Input value={updatedFields?.IMEI} name="IMEI" onChange={updateFields} required /></div><br />
                            <div>NFTID:<br /> <Input value={updatedFields?.algorandNFTID} name="algorandNFTID" onChange={updateFields} /></div><br />
                            <div>Location:<br /> <Input value={updatedFields?.location} name="location" onChange={updateFields} /></div><br />
                            <div>QR Code:<br /> <Input value={updatedFields?.qrCode} name="qrCode" onChange={updateFields} /></div><br />
                            {/* <div>Tokens:<br/> <Input value={updatedFields?.tokens} name="tokens" onChange={updateFields} /></div><br /> :<br/> vehicle?.tokens} <br /> */}
                            <div>Charge Precentage:<br /> <Input value={updatedFields?.chargePrecentage} name="chargePrecentage" onChange={updateFields} /></div><br />
                            <div>Token 1 Owner:<br /> <Input value={updatedFields?.token1Owner} name="token1Owner" onChange={updateFields} /></div><br />
                            <div>Token 2 Owner:<br /> <Input value={updatedFields?.token2Owner} name="token2Owner" onChange={updateFields} /></div><br />
                            <div>Token 3 Owner:<br /> <Input value={updatedFields?.token3Owner} name="token3Owner" onChange={updateFields} /></div><br />
                            <div>Token 4 Owner:<br /> <Input value={updatedFields?.token4Owner} name="token4Owner" onChange={updateFields} /></div><br />
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" type='submit'>Add Vehicle</Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
