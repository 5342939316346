import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import MultiSelect from '../components/common/MultiSelect';
import CustomDataTable from '../components/CustomDataTable';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/firebase.config';
import GoogleMapWrapper from '../components/common/GoogleMapWrapper';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import ToggleButton from '../components/common/ToggleButton';
import { CustomizedListItem } from '../components/Vehicles/CustomizedListItem';
import CloseIcon from '@mui/icons-material/Close';
import { NewVehicle } from '../components/Vehicles/NewVehicle';
const headings = [
    {
        id: 'sl',
        align: "left",
        label: 'No.',
        hidden: true,
    },
    {
        id: 'plateNumber',
        align: "left",
        label: 'Plate Number',
        required: true,
        details: true
    },
    {
        id: 'vinNumber',
        align: "left",
        label: 'Vin Number',
    },
    {
        id: 'brandName',
        align: "left",
        label: 'Brand Name',
    },
    {
        id: 'serialNumber',
        align: "left",
        label: 'Serial Number',
    },
    {
        id: 'IMEI',
        align: "left",
        label: 'IMEI',
    },
    {
        id: 'algorandNFTID',
        align: "left",
        label: 'NFT ID',
    }
];


const Vehicles = () => {
    const localStorageKey = 'vehicles-column';
    const [columns, setColumns] = useState(JSON.parse(localStorage.getItem(localStorageKey) || '["sl", "plateNumber", "vinNumber", "brandName"]'))
    const [isListView, setIsListView] = useState(JSON.parse(localStorage.getItem("isListView") || "false"));
    const [registeredVehicles, setRegisteredVehicles] = useState([]);
    const [multipleData, setMultipleData] = useState([]);
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "registeredVehicle"), (doc) => {
            const data = doc.docs.map(item => item.data());
            setRegisteredVehicles(data);
        });
        return () => {
            unsubscribe();
        }
    }, [])

    const selectedItem = (lat, lng) => {
        const newData = registeredVehicles.filter(({ location }) => parseFloat(location?.split(",")[0]) === lat && parseFloat(location?.split(",")[1]) === lng);
        setMultipleData(newData)
    }

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <ToggleButton value={isListView} setValue={setIsListView} />
                <NewVehicle />
            </Box>
            {
                isListView ?
                    <>
                        <MultiSelect
                            localStorageKey={localStorageKey}
                            columns={columns}
                            setColumns={setColumns}
                            totalColumns={headings}
                        />
                        <CustomDataTable
                            columns={columns}
                            rows={registeredVehicles}
                            headings={headings}
                            uniqueField="plateNumber"
                            detailsPath="vehicle-details"
                        />
                    </> :
                    <Box sx={{ position: "relative" }}>
                        <GoogleMapWrapper
                            multipleData={multipleData}
                            setMultipleData={setMultipleData}
                            selectedItem={selectedItem}
                            data={registeredVehicles}
                            type="registeredVehicles"
                        />
                        <Box
                            sx={{
                                maxHeight: "500px",
                                overflowY: "auto",
                                position: "absolute",
                                top: 10, right: 60,
                                bgcolor: theme => theme.palette.background.paper
                            }}>
                            {
                                multipleData?.length > 0 && (
                                    <>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography sx={{ mt: 1, ml: 2, color: "text.primary" }}>{multipleData.length === 1 ? "Vehicle" : "Vehicles"}</Typography>
                                            <IconButton onClick={() => setMultipleData([])}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                        <Divider />
                                        {
                                            multipleData?.map((item, i) => (
                                                <CustomizedListItem key={i} item={item} />
                                            ))
                                        }
                                    </>
                                )
                            }
                        </Box>
                    </Box>
            }
        </>
    );
};

export default Vehicles;