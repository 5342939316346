import { axiosTokenInstance } from "../../utils/axios"

export const getRegisteredVehicles = async () => {
    const { data } = await axiosTokenInstance.get("/vehicles/get-vehicles");
    return data;
}

export const updateVehicleDetails = async (requestBody) => {
    const { data } = await axiosTokenInstance.post("vehicles/update-vehicle", requestBody);
    return data;
}

export const createVehicle = async (requestBody) => {
    const { data } = await axiosTokenInstance.post("vehicles/create-vehicle", requestBody);
    return data;
}